<template>
  <div class="container page">
    <van-nav-bar :title="$t('redeemPoints')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <!-- <template #right>
        <span class="nav-right" @click="$router.push({ path: '/WithdrawRecord' })">{{ $t('exchangeRecords') }}</span>
      </template> -->
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{ $t('redeemPoints') }}（{{ $t('currencySymbol') }}）</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{ $t('whole') }}</span>
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <div v-html="rules"></div>
              </div>
              <template #reference>
                <div>
                  <van-icon name="info-o" />
                  {{ $t('limitDescription') }}
                </div>
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>{{ $t('residualIntegral') }}：</span>
            <span class="number">{{ this.userInfo.balance }}{{ $t('individual') }}</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{ $t('redeemPointsNow') }}</van-button>

    </div>
    <van-popup v-model="show" position="bottom" closeable round safe-area-inset-bottom :style="{ height: '50%' }">
      <div class="payPwdPop">
        <p class="title">{{ $t('payPwdPl') }}</p>
        <van-password-input :value="opw" :length="4" :gutter="10" focused />
        <van-number-keyboard v-model="opw" :show="oshowKeyboard" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: "",
      userInfo: {},
      rules: "",
      show: false,
      opw: '',
      oshowKeyboard: true,
    };
  },
  watch: {
    opw(v) {
      if (v.length == 4) {
        this.show = false;
        this.$httpNew({
          method: 'post',
          data: {
            num: this.withdraw_money,
            user_id: localStorage.getItem("token"),
            paypassword: v,
          },
          url: 'user_set_withdraw'
        }).then(res => {
          this.$toast(res.info);
          if (res.code == 0) {
            location.reload()
            this.getUserInfo();
            this.getUserWithdrawRole();
          }
        })
      }
    }
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$httpNew({
        method: 'post',
        url: 'user_info',
        data: {
          user_id: localStorage.getItem("token"),
        },
      }).then(res => {
        if (res.code === 0) {
          this.userInfo = res.info || {};
        }
      })
    },
    // 提现规则
    getUserWithdrawRole() {
      this.$httpNew({
        method: 'get',
        data: {
          id: 14,
        },
        url: 'get_notice'
      }).then(res => {
        if (res.code == 0) {
          this.rules = res.data.content;
        } else {
          this.rules = "";
        }
      })
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.balance;
    },
    doWithdraw() {
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t('fillInPoints'));
      } else {
        this.opw = "";
        this.show = true;
      }
    },
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

.container .main {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}

.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}

.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money .all {
  color: #d10404;
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
}

.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}

.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .information .balance .number {
  color: #d10404;
}

.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  // background: linear-gradient(90deg, #e6c3a1, #7e5678);
  background: linear-gradient(90deg, #6529c9, #cc2996);
}

.payPwdPop .title {
  margin: 40px 0;
  line-height: 20px;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

::v-deep .van-password-input {
  width: 80%;
  height: 150px;
  margin: 0 auto;

}

::v-deep .van-password-input__security li {
  font-size: 30px;
  line-height: 30;
  background-color: #ebedf0;
}

::v-deep .van-password-input__security {
  height: 130px;
}

::v-deep .van-password-input .van-password-input__security .van-password-input__item {
  height: 100%;
  border: 0;
  text-align: center;
  border-radius: 30px;
}

.van-password-input__security i {
  width: 25px;
  height: 25px;
}

::v-deep .van-key {
  height: 100px;
  font-size: 55px;
  line-height: 20px;
  border-radius: 20px;
}

::v-deep .van-number-keyboard {
  z-index: 100;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f2f3f5;
}

::v-deep .van-key__collapse-icon {
  width: 50px;
  height: 50px;
}

::v-deep .van-key__delete-icon {
  width: 50px;
  height: 50px;
}

::v-deep .van-popup__close-icon {
  font-size: 18px;
}
</style>
