<template>
  <div class="container page">
    <van-nav-bar :title="$t('setPayPwd')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="item">
        <p class="title">{{ $t('oldPayPwd') }}</p>
        <van-password-input :value="old_pwd" :length="4" :gutter="10" :focused="lshowKeyboard"
          @focus="lshowKeyboard = true; tshowKeyboard = false; oshowKeyboard = false;" />
        <van-number-keyboard v-model="old_pwd" :show="lshowKeyboard" @blur="lshowKeyboard = false" />
      </div>
      <div class="item">
        <p class="title">{{ $t('payPwdPl') }}</p>
        <van-password-input :value="opw" :length="4" :gutter="10" :focused="oshowKeyboard"
          @focus="oshowKeyboard = true; lshowKeyboard = false; tshowKeyboard = false;" />
        <van-number-keyboard v-model="opw" :show="oshowKeyboard" @blur="oshowKeyboard = false" />
      </div>
      <div class="item">
        <p class="title">{{ $t('enterPayPwdPl') }}</p>
        <van-password-input :value="tpw" :length="4" :gutter="10" :focused="tshowKeyboard"
          @focus="tshowKeyboard = true; oshowKeyboard = false; lshowKeyboard = false;" />
        <van-number-keyboard v-model="tpw" :show="tshowKeyboard" @blur="tshowKeyboard = false" />
      </div>
      <van-button class="sub-btn" type="default" @click="setPayPassword()">{{ $t('submitTo') }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opw: '',
      old_pwd: '',
      tpw: '',
      lshowKeyboard: true,
      oshowKeyboard: false,
      tshowKeyboard: false,
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    setPayPassword() {
      this.lshowKeyboard = false;
      this.oshowKeyboard = false;
      this.tshowKeyboard = false;
      if (this.opw.length !== 4) {
        this.oshowKeyboard = true;
        this.$toast(this.$t('complete'));
        return false;
      }
      if (this.tpw.length !== 4) {
        this.tshowKeyboard = true;
        this.$toast(this.$t('complete'));
        return false;
      }
      if (this.opw !== this.tpw) {
        this.$toast(this.$t('passwordInconsistency'));
        return false;
      } else {
        this.$httpNew({
          method: 'post',
          data: {
            old_pwd: this.old_pwd,
            new_pwd: this.opw,
            user_id: localStorage.getItem("token"),
            type: 2,
          },
          url: 'user_set_pwd'
        }).then(res => {
          this.$toast(res.info);
          if (res.code == 0) {
            this.$router.push("Mine");
          }
        })
      }
    },
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .wrapper {
  height: 100%;
  //overflow-y: auto;
  background-color: #fff;
  //-webkit-overflow-scrolling: touch;
}

.container .wrapper .item .title {
  margin: 40px 0;
  line-height: 20px;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

::v-deep .van-password-input {
  width: 80%;
  height: 150px;
  margin: 0 auto;

}

::v-deep .van-password-input__security li {
  font-size: 30px;
  line-height: 30;
  background-color: #ebedf0;
}

::v-deep .van-password-input__security {
  height: 130px;
}

::v-deep .van-password-input .van-password-input__security .van-password-input__item {
  height: 100%;
  border: 0;
  text-align: center;
  border-radius: 30px;
}

.van-password-input__security i {
  width: 25px;
  height: 25px;
}

::v-deep .van-key {
  height: 100px;
  font-size: 55px;
  line-height: 20px;
  border-radius: 20px;
}

::v-deep .van-number-keyboard {
  z-index: 100;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f2f3f5;
}

::v-deep .van-key__collapse-icon {
  width: 50px;
  height: 50px;
}

::v-deep .van-key__delete-icon {
  width: 50px;
  height: 50px;
}

.container .wrapper .sub-btn {
  margin: 110px 0 0 10%;
  height: 100px;
  width: 80%;
  font-size: 35px;
  border-radius: 50px;
  color: #fff;
  // background: linear-gradient(270deg, #e6c3a1, #7e5678);
  background: linear-gradient(90deg, #6529c9, #cc2996);
}
</style>
