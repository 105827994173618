<template>
    <div class="container page">
        <van-nav-bar :title="$t('History')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back" />
            </template>
        </van-nav-bar>
        <div class="HistoryPage">
            <van-swipe :autoplay="3000" indicator-color="white" height="250" style="margin-bottom: 10px;">
                <van-swipe-item v-for="(v, key) in banners" :key="key">
                    <van-image :src="v.goods_pic" :height="250" width="100%" fit="cover">
                        <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                        </template>
                        <template v-slot:error><van-image src="/img/error.png" /></template>
                    </van-image>
                </van-swipe-item>
            </van-swipe>
            <div style="padding: 10px 0;">
                <van-tabs v-model="active" background="transparent" title-active-color="#ee0a24" line-height="0"
                    @change="changeTab">
                    <van-tab :name="1" :title="$t('Pending')"></van-tab>
                    <van-tab :name="2" :title="$t('freeze')"></van-tab>
                    <van-tab :name="3" :title="$t('Completed')"></van-tab>
                </van-tabs>
            </div>
            <div>
                <van-list v-model="loading" :finished="finished" :loading-text="$t('loadingtext')"
                    :finished-text="$t('finishedtext')" @load="onLoad" class="HistoryList">
                    <div class="item" v-for="(v, i) in list" :key="i" @click="toDetail(v)">
                        <div>
                            <div>
                                <div class="title">
                                    {{ $t('Submissiontime') }}:
                                    {{ new Date(v.addtime * 1000).toLocaleDateString().replace(/\//g, "-") + " " + new
            Date(v.addtime * 1000).toTimeString().substr(0, 8) }}
                                </div>
                                <div class="Completed Pending" v-if="active == 1">{{ $t('Pending') }}</div>
                                <div class="Completed freeze" v-if="active == 2">{{ $t('freeze') }}</div>
                                <div class="Completed" v-if="active == 3">{{ $t('Completed') }}</div>
                            </div>
                            <div class="PayBtn" v-if="active == 1" @click.stop="Pay(v)">{{ $t('Pay') }}</div>
                            <img src="../../assets/freeze.png" alt="" v-if="active == 2">
                            <img src="../../assets/Completed.png" alt="" v-if="active == 3">
                        </div>
                        <div>
                            <img :src="v.goods_pic" alt="">
                            <div>
                                <div>{{ v.goods_name }}</div>
                                <div>
                                    <van-tag type="danger">10.0</van-tag>
                                    <!-- <span style="margin-left: 15px;">{{ v.goods_id }} {{ $t('reviews') }}</span> -->
                                </div>
                                <div>{{ v.id }}</div>
                                <div>{{ v.num }} {{ $t('MAD') }}</div>
                                <div>{{ $t('includestaxescharges') }}</div>
                                <div v-if="active == 1">
                                    {{ $t('Payremainingtime') }}:
                                    <van-count-down format="mm:ss" :time="v.lesstime * 1000" @finish="finishDown(v)" />
                                </div>
                            </div>
                        </div>
                        <div><van-divider> </van-divider></div>
                        <div>
                            <div>
                                <span>{{ $t('Totalamount') }}</span>
                                <span>{{ v.num }} {{ $t('MAD') }}</span>
                            </div>
                            <div>
                                <span>{{ $t('OrderQuantity') }}</span>
                                <span>{{ v.goods_count }}</span>
                            </div>
                            <div>
                                <span>{{ $t('Expectedreturn') }}</span>
                                <span>{{ v.commission }} {{ $t('MAD') }}</span>
                            </div>
                        </div>
                        <div><van-divider> </van-divider></div>
                    </div>
                </van-list>
            </div>
        </div>
        <van-popup v-model="showQD" :style="{ width: '90%' }">
            <img :src="hotelInfo.goods_pic" alt="" class="QDimg">
            <div class="QDbox">
                <div>{{ $t('Restaurantname') }}: {{ hotelInfo.goods_name }}</div>
                <div>{{ $t('Totalamount') }}: {{ hotelInfo.num }} {{ $t('MAD') }}</div>
                <div>{{ $t('OrderQuantity') }}: {{ hotelInfo.goods_count }}</div>
                <div>{{ $t('Expectedreturn') }}: {{ hotelInfo.commission }} {{ $t('MAD') }}</div>
                <div>{{ $t('Ordernumber') }}: {{ hotelInfo.id }}</div>
                <div>{{ $t('Submissiontime') }}: {{ new Date(hotelInfo.addtime *
            1000).toLocaleDateString().replace(/\//g, "-")
            + " " + new
                Date(hotelInfo.addtime * 1000).toTimeString().substr(0, 8) }}</div>
            </div>
            <div class="GrabOrder" style="border-radius: 8px 8px 0 0;" @click="SubmitForm">{{ $t('Submit') }}</div>
        </van-popup>
        <van-dialog width="80%" v-model="showKF" confirmButtonColor="#8d47ef"
            :confirmButtonText="$t('confirmButtonText')" :message="kfmessage" @confirm="toService"></van-dialog>
    </div>
</template>

<script>
import config from "../../http/config";
export default {
    data() {
        return {
            config,
            active: 1,
            showQD: false,
            page: 1,
            list: [],
            count: 0,
            loading: false,
            finished: false,
            hotelInfo: {},
            banners: [],
            showKF: false,
            kfmessage: "",
        }
    },
    mounted() {
        this.active = Number(this.$route.query.active) || 1;
        this.loading = true;
        this.getList();
        this.$httpNew({
            method: 'post',
            data: {
                page: 1,
                num: 10,
                type: 3,
                user_id: localStorage.getItem("token"),
            },
            url: 'order_list'
        }).then(res => {
            if (res.code == 0 && res.data && res.data.length > 3) {
                this.banners = this.reorganizationArr(res.data)[0];
            } else {
                this.getgoodslist();
            }
        }).catch(() => {
            this.getgoodslist();
        })
    },
    methods: {
        toService() {
            let tel = this.$store.getters.getUserInfo.tel;
            let kefu = this.$store.getters.getBaseInfo.kefu.replace(/\s*/g, "");
            let metadata = `&metadata={"address":"111","age":"18","comment":"111","email":"123@qq.com","gender":"男","name":"${tel}","qq":"123", "tel":"${tel}","weibo":"111","weixin":"111"}`;
            return window.location.href = kefu + metadata;
        },
        getgoodslist() {
            this.$httpNew({
                method: 'post',
                data: {},
                url: 'goodslist'
            }).then(res => {
                if (res.code == 0) {
                    this.banners = this.reorganizationArr(res.data || [])[0];
                } else {
                    this.banners = [];
                }
            }).catch(() => {
                this.banners = [];
            })
        },
        reorganizationArr(data) {
            var result = [];
            for (var i = 0; i < data.length; i += 3) {
                result.push(data.slice(i, i + 3));
            }
            return result;
        },
        back() {
            return window.history.back();
        },
        changeTab() {
            this.loading = true;
            this.finished = false;
            this.page = 1;
            this.list = [];
            this.getList();
        },
        getList() {
            this.$httpNew({
                method: 'post',
                data: {
                    page: this.page,
                    num: 10,
                    type: this.active,
                    user_id: localStorage.getItem("token"),
                },
                url: 'order_list'
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    this.list = this.list.concat(res.data);
                    this.count = res.count;
                }
                if (this.list.length == this.count) {
                    this.finished = true;
                }
            })
        },
        onLoad() {
            this.page++;
            this.getList();
        },
        finishDown(v) {
            if (v.lesstime >= 0) {
                this.loading = true;
                this.page = 1;
                this.list = [];
                this.getList();
            }
        },
        Pay(v) {
            this.hotelInfo = v;
            this.showQD = true;
        },
        toDetail(v) {
            this.$router.push({ path: '/fakeTransactionsDetail', query: { id: v.goods_id } });
        },
        SubmitForm() {
            this.$toast.loading({
                message: this.$t('loadingtext'),
                forbidClick: true,
            });
            this.$httpNew({
                method: 'post',
                data: {
                    oid: this.hotelInfo.id,
                    user_id: localStorage.getItem("token"),
                },
                url: 'do_order'
            }).then(res => {
                this.$toast.clear();
                if (res.code == 0) {
                    this.$toast(res.info);
                    // this.loading = true;
                    // this.active = 3;
                    // this.page = 1;
                    // this.list = [];
                    // this.getList();
                    this.showQD = false;
                    this.back();
                } else if (res.code == 2) {
                    // 余额不足，弹窗提示前往客服
                    this.kfmessage = res.info;
                    this.showKF = true;
                } else {
                    this.$toast(res.info);
                }
            })
        },
    }
}
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.HistoryPage {
    padding: 5px;
    background: #fff;
    height: 100%;

    .HistoryList {
        .item {
            padding: 10px 10px 0;
            font-size: 14px;

            >div:nth-child(1) {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                .title {
                    color: #646566;
                    margin-bottom: 5px;
                }

                .Completed {
                    color: #4caf50;
                    border-radius: 10px;
                    border: 1px solid #4caf50;
                    width: 170px;
                    text-align: center;
                    padding: 2px 0;
                }

                .Completed.Pending {
                    color: #9c27b0;
                    border: 1px solid #9c27b0;
                }

                .Completed.freeze {
                    color: #f44336;
                    border: 1px solid #f44336;
                }

                .PayBtn {
                    background: linear-gradient(rgb(254, 89, 134), rgb(255, 132, 139));
                    color: #fff;
                    border-radius: 8px;
                    padding: 0 20px;
                    text-align: center;
                    font-size: 16px;
                    height: 50px;
                    line-height: 50px;
                }

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }
            }

            >div:nth-child(2) {
                display: flex;
                justify-content: space-between;

                >img {
                    width: 40%;
                    height: 350px;
                    object-fit: cover;
                }

                >div {
                    width: 60%;
                    padding: 0 10px;

                    >div:nth-child(1) {
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    >div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                    }

                    >div:nth-child(3) {
                        padding: 4px 0;
                        margin-bottom: 10px;
                        background: #009688;
                        color: #fff;
                        width: 300px;
                        text-align: center;
                    }

                    >div:nth-child(4) {
                        text-align: right;
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }

                    >div:nth-child(5) {
                        text-align: right;
                        font-size: 12px;
                        color: #646566;
                    }

                    >div:nth-child(6) {
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        color: red;
                        font-weight: bold;
                        margin: 10px 0;

                        .van-count-down {
                            color: red;
                            margin-left: 10px;
                            font-weight: bold;
                        }
                    }
                }
            }

            >div:nth-child(4) {
                >div {
                    color: #646566;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 4px 0;
                    font-size: 12px;
                }
            }
        }
    }
}

.QDimg {
    width: 100%;
    height: 500px;
    margin-right: 10px;
    display: block;
    object-fit: cover;
    margin: 0;
}

.QDbox {
    padding: 15px;

    >div {
        padding: 5px 0;
        font-size: 14px;
        box-sizing: border-box;
    }
}

.GrabOrder {
    background: linear-gradient(rgb(254, 89, 134), rgb(255, 132, 139));
    color: #fff;
    border-radius: 8px;
    padding: 10px 0;
    text-align: center;
    font-size: 32px;
    margin-top: 20px;
    line-height: 50px;
}
</style>