<template>
    <div class="container page">
        <van-nav-bar :title="$t('basicInformation')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="main-content">
            <!-- @click="openHerderImg()" -->
            <div class="item van-hairline--bottom">
                <div class="left">{{ $t('headSculpture') }}</div>
                <div class="right">
                    <img src="@/assets/avatar.png">
                    <!-- <van-icon name="arrow" /> -->
                </div>
            </div>
            <van-popup v-model="show" position="bottom" round :style="{ height: '50%' }">
                <div class="avatarbox">
                    <div class="title van-hairline--bottom">
                        <van-icon @click="show = false" name="cross" />
                        <div class="text">アバターを選択</div>
                        <div class="btnok" @click="check()">定義する</div>
                    </div>
                    <div class="content">
                        <van-image round v-for="(item, index) in 185" :key="index"
                            @click="select_header_img('https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' + item + '.png')"
                            :class="{ 'choose': isActive === 'https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' + item + '.png' }"
                            :src="'https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' + item + '.png'" />
                    </div>
                </div>
            </van-popup>
            <div class="item van-hairline--bottom" @click="toSetName()">
                <div class="left">{{ $t('fullName') }}</div>
                <div class="right">
                    <span class="desc">{{ this.userInfo.tel || '' }}</span>
                    <van-icon name="arrow" />
                </div>
            </div>
            <!-- <div class="item van-hairline--bottom" @click="toSetSex()">
                <div class="left">{{ $t('gender') }}</div>
                <div class="right">
                    <span class="desc">{{ this.userInfo.sex !== "0" ? this.userInfo.sex === "1" ? $t('male') :
            $t('female')
            :
            $t('unknown') }}</span>
                    <van-icon name="arrow" />
                </div>
            </div> -->
            <div class="item van-hairline--bottom" @click="toSetBank()">
                <div class="left">{{ $t('bankCardInformation') }}</div>
                <div class="right">
                    <span class="desc">{{ this.isBank ? $t('have') : $t('notHave') }}</span>
                    <van-icon name="arrow" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
            show: false,
            isBank: false,
            userInfo: {}
        };
    },
    methods: {
        back() {
            return window.history.back();
        },
        toSetName() {
            this.$router.push({ path: '/Setname' });
        },
        toSetBank() {
            this.$router.push({ path: '/Setbank' });
        },
        toSetSex() {
            this.$router.push({ path: '/Setsex' });
        },
        openHerderImg() {
            this.show = true;
        },
        select_header_img(url) {
            this.isActive = url;
        },
        check() {
            this.$http({
                method: 'post',
                data: { header_img: this.isActive },
                url: 'user_header_img'
            }).then(res => {
                if (res.code === 200) {
                    this.getUserInfo();
                    this.$toast(res.msg);
                    this.show = false;
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        getUserInfo() {
            this.$httpNew({
                method: 'post',
                url: 'user_info',
                data: {
                    user_id: localStorage.getItem("token"),
                },
            }).then(res => {
                if (res.code == 0) {
                    this.userInfo = res.info || {};
                }
            })
        },
        getUserBankInfo() {
            this.$http({
                method: 'get',
                url: 'user_get_bank'
            }).then(res => {
                if (res.code === 200) {
                    if (res.data.is_bank) {
                        this.isBank = true;
                    } else {
                        this.isBank = false;
                    }
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        }
    },
    created() {
        if (!localStorage.getItem('token')) {
            this.$router.push({ path: '/Login' })
        } else {
            this.getUserInfo();
            this.getUserBankInfo();
        }
    }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .main-content {
    padding: 0 20px;
    background-color: #fff;
}

.container .main-content .item {
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
}

.container .main-content .item .right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container .main-content .item .right img {
    width: 90px;
}

.container .main-content .van-hairline--bottom::after {
    border-bottom-width: 3px;
}

.container .main-content .item .right .desc-cell-number,
.container .main-content .item .right .desc {
    font-size: 30px;
    font-weight: 700;
    color: #979799;
}

.avatarbox {
    padding: 15px;
    color: #000;
    height: 81%;
    background-color: #fff;
}

.avatarbox .title {
    padding: 8px 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
}

.avatarbox .content .van-image {
    width: 105px;
    height: 105px;
    margin: 2.5%;
    border-radius: 50%;
}

.avatarbox .content {
    padding-bottom: 10px;
    height: 100%;
    overflow-y: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.avatarbox .content .choose {
    width: 95px;
    height: 95px;
    border: 6px solid #e6c3a1;
}
</style>
