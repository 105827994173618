<template>
	<van-tabbar v-if="show" v-model="active" active-color="#8d47ef" :border="true" inactive-color="#979799"
		z-index="999999">
		<van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
			<span>{{ k.title }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" />
				<!-- <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key === 2" /> -->
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Home',
					title: this.$t("tabbar1"),
					icon: {
						active: require('../../public/img/footer/首页选中.png'),
						noactive: require('../../public/img/footer/首页未选中.png')
					}
				},
				{
					router: '/Video',
					title: this.$t("tabbar2"),
					icon: {
						active: require('../../public/img/footer/预约选中.png'),
						noactive: require('../../public/img/footer/预约未选中.png')
					}
				},
				{
					router: '/Choose',
					title: this.$t("tabbar3"),
					icon: {
						active: require('../../public/img/footer/选妃选中.png'),
						noactive: require('../../public/img/footer/选妃未选中.png')
					}
				},
				{
					router: '/Lottery',
					title: this.$t("tabbar4"),
					icon: {
						active: require('../../public/img/footer/视频选中.png'),
						noactive: require('../../public/img/footer/视频未选中.png')
					}
				},
				{
					router: '/Mine',
					title: this.$t("tabbar5"),
					icon: {
						active: require('../../public/img/footer/我的选中.png'),
						noactive: require('../../public/img/footer/我的未选中.png')
					}
				}
			]
		};
	},
	methods: {},
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'video') {
				this.active = 1;
				this.show = true;
			} else if (to.name == 'choose') {
				this.active = 2;
				this.show = true;
			} else if (to.name == 'Lottery') {
				this.active = 3;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 4;
				this.show = true;
			} else {
				this.show = false;
			}
		}
	},
	created() {
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'video') {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == 'choose') {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == 'Lottery') {
			this.active = 3;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 4;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;

.van-tabbar {
	height: @tabbar-height;
}

.van-tabbar-item__icon img {
	// height: @tabbar-img;
	height: 50px;
}

.van-tabbar-item {
	font-size: 26px;
}

.tui {
	width: 4rem;
	margin-top: -10.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}

[class*='van-hairline']::after {
	border: none !important;
}

::v-deep .van-tabbar-item__text span {
	font-size: 12px;
	zoom: 0.9;
}
</style>
