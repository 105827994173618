<template>
    <div class="container page">
        <van-nav-bar :title="$t('Fundingdetails')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="main">
            <van-tabs v-model="active" title-inactive-color="#442889" title-active-color="#c571cf" animated
                @change="TabChange" style="margin-bottom: 10px;">
                <van-tab :title="$t('recharge')" :name="1" :title-style="{ fontWeight: 'bold' }"></van-tab>
                <van-tab :title="$t('Withdrawal')" :name="2" :title-style="{ fontWeight: 'bold' }"></van-tab>
                <van-tab :title="$t('AccountDetails')" :name="3" :title-style="{ fontWeight: 'bold' }"></van-tab>
            </van-tabs>
            <div>
                <van-list v-model="loading" :finished="finished" :loading-text="$t('loadingtext')"
                    :finished-text="$t('finishedtext')" @load="onLoad" class="HistoryList">
                    <van-cell v-for="(v, i) in list" :key="i"
                        :title="new Date(v.addtime * 1000).toLocaleDateString().replace(/\//g, '-') + ' ' + new Date(v.addtime * 1000).toTimeString().substr(0, 8)"
                        center style="margin-bottom: 10px;">
                        <template #label>
                            <van-tag color="#9c27b0" round style="margin-top: 5px; padding: 5px 10px;"
                                v-if="active == 1">{{ $t('recharge') }}</van-tag>
                            <van-tag color="#9c27b0" round style="margin-top: 5px; padding: 5px 10px;"
                                v-if="active == 2">{{ $t('Withdrawal') }}</van-tag>
                            <van-tag color="#9c27b0" round style="margin-top: 5px; padding: 5px 10px;"
                                v-if="active == 3">
                                {{ [$t('SystemOperation'), $t('recharge'), $t('trade'), $t('Rebate'),
        $t('ForcedTransaction'), $t('PromotionRebate'), $t('Subleveltransactionrebate'),
        $t('Withdrawal')][v.type] }}
                            </van-tag>
                        </template>
                        <template #right-icon>
                            <span v-if="active == 1" style="color: #f44336; font-weight: bold;">+{{ v.num }}</span>
                            <span v-if="active == 2" style="color: #4caf50; font-weight: bold;">-{{ v.num }}</span>
                            <div v-if="active == 3">
                                <span v-if="v.status == 1" style="color: #f44336; font-weight: bold;">+{{ v.num
                                    }}</span>
                                <span v-if="v.status == 2" style="color: #4caf50; font-weight: bold;">-{{ v.num
                                    }}</span>
                            </div>
                        </template>
                    </van-cell>
                </van-list>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 1,
            page: 1,
            list: [],
            count: 0,
            loading: false,
            finished: false,
        };
    },
    methods: {
        TabChange(v) {
            this.active = v;
            this.loading = true;
            this.finished = false;
            this.page = 1;
            this.list = [];
            this.getfunding_details();
        },
        back() {
            return window.history.back();
        },
        getfunding_details() {
            this.$httpNew({
                method: 'post',
                url: 'funding_details',
                data: {
                    user_id: localStorage.getItem("token"),
                    page: this.page,
                    limit: 10,
                    type: this.active,
                },
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    this.list = this.list.concat(res.data);
                    this.count = res.count;
                }
                if (this.list.length == this.count) {
                    this.finished = true;
                }
            })
        },
        onLoad() {
            this.page++;
            this.getfunding_details();
        },
    },
    created() {
        if (!localStorage.getItem('token')) {
            this.$router.push({ path: '/Login' })
        } else {
            this.getfunding_details();
        }
    }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000000;
    font-size: 35px;
}

::v-deep .van-loading__text {
    color: #000000;
    font-size: 35px;
}

.container .main {
    position: relative;
    overflow: auto;
    background-color: #fff;
    height: 100%;
    padding: 10px 0 0;
}

.item_list {
    padding: 15px 15px;
    margin: 30px 10px;
    background: #fff;
    border-radius: 10px;
    line-height: 60px;
}

.item_list .topInfo span {
    flex: 1;
    font-size: 35px;
    font-weight: 700;
    color: #ff253f;
}

.item_list .time span {
    flex: 1;
    font-size: 25px;
    font-weight: 500;
    color: #000;
}

.item_list .topInfo span:last-child {
    float: right;
}

.item_list .desc span {
    font-size: 25px;
    font-weight: 700;
    color: #9b9b9b;
}

.item_list .cover {
    width: 60px;
    height: 60px;
    -o-object-fit: cover;
    object-fit: cover;
}

.item_list .period-number {
    margin-left: 50px;
    margin-right: 10px;
    height: 50px;
    line-height: 60px;
    font-size: 35px;
    font-weight: 700;
    color: #000;
}

.item_list .lottery_info {
    display: flex;
}

.recent {
    display: flex;
    align-items: center;
    height: 100px;
}

.kuaisan-ball .left {
    justify-content: flex-start;
}

.kuaisan-ball {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.kuaisan-ball .res-img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
}

.kuaisan-ball .res-des {
    font-weight: 700;
    text-align: center;
    color: #000;
}

.kuaisan-ball .res-des.middle {
    width: 15%;
    font-size: 35px;
}

.resgardenBox {
    background: linear-gradient(90deg, #f560cd, #4f2b8a);
    color: #fff;
    width: 52px;
    height: 52px;
    line-height: 52px;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    // margin: 0 5px;
}

.ressquareBox {
    background: linear-gradient(90deg, #f560cd, #4f2b8a);
    color: #fff;
    height: 52px;
    line-height: 52px;
    border-radius: 8px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    // margin-left: 5px;
    padding: 0 20px;
    min-width: 100px;
}

::v-deep .van-tab {
    font-size: 30px;
    line-height: 80px;
    font-weight: bold;
}

::v-deep .van-tabs__line {
    background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    height: 80px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}
</style>