<template>
  <div class="container page">
    <van-nav-bar :title="$t('changeLoginPassword')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <van-cell-group style="padding: 10px 20px;">
      <div>
        <div style="padding: 10px 0;">{{ $t('oldPwd') }}</div>
        <van-field v-model="old_password" label="" :placeholder="$t('oldPwdPlaceholder')" :type="passwordType1">
          <template slot="right-icon">
            <van-icon :name="passwordType1 === 'password' ? 'closed-eye' : 'eye-o'"
              @click="passwordType1 = passwordType1 === 'password' ? 'text' : 'password'" />
          </template>
        </van-field>
      </div>
      <div>
        <div style="padding: 10px 0;">{{ $t('newPwd') }}</div>
        <van-field v-model="o_new_password" label="" :placeholder="$t('newPwdPlaceholder')" :type="passwordType2">
          <template slot="right-icon">
            <van-icon :name="passwordType2 === 'password' ? 'closed-eye' : 'eye-o'"
              @click="passwordType2 = passwordType2 === 'password' ? 'text' : 'password'" />
          </template>
        </van-field>
      </div>
      <div>
        <div style="padding: 10px 0;">{{ $t('newPwd') }}</div>
        <van-field v-model="t_new_password" label="" :placeholder="$t('againOldPwdPlaceholder')" :type="passwordType3">
          <template slot="right-icon">
            <van-icon :name="passwordType3 === 'password' ? 'closed-eye' : 'eye-o'"
              @click="passwordType3 = passwordType3 === 'password' ? 'text' : 'password'" />
          </template>
        </van-field>
      </div>
    </van-cell-group>
    <div style="padding: 30px 20px;">
      <van-button class="sign-out" type="primary" size="normal" @click="save()">{{ $t('save') }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      o_new_password: "",
      t_new_password: "",
      old_password: "",
      passwordType1: 'password',
      passwordType2: 'password',
      passwordType3: 'password',
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    save() {
      if (this.old_password === "" || this.old_password === null || this.old_password === undefined) {
        this.$toast(this.$t('oldPwdPlaceholder'));
        return false;
      }
      if (this.o_new_password === "" || this.o_new_password === null || this.o_new_password === undefined) {
        this.$toast(this.$t('newPwdPlaceholder'));
        return false;
      }
      if (this.t_new_password === "" || this.t_new_password === null || this.t_new_password === undefined) {
        this.$toast(this.$t('againOldPwdPlaceholder'));
        return false;
      }
      if (this.o_new_password !== this.t_new_password) {
        this.$toast(this.$t('passwordInconsistency'));
        return false;
      }
      this.$httpNew({
        method: 'post',
        data: {
          user_id: localStorage.getItem("token"),
          old_pwd: this.old_password,
          new_pwd: this.o_new_password,
          type: 1,
        },
        url: 'user_set_pwd'
      }).then(res => {
        this.$toast(res.info);
        if (res.code == 0) {
          setTimeout(() => {
            localStorage.clear();
            this.$router.push("Login");
          }, 500);
        }
      })
    },
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  padding: 10px 0;
  margin-bottom: 10px;
}

.sign-out {
  height: 90px;
  width: 100%;
  line-height: 90px;
  border-radius: 14px;
  color: #fff;
  font-size: 32px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #6529c9, #cc2996);
}
</style>
