<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar newnavbar" :title="$t('tabbar4')" @click-right="toService">
      <template #right>
        <van-icon name="chat-o" size="24" color="#fff" />
      </template>
    </van-nav-bar>
    <div class="convention-item">
      <!-- <div class="left">
        <van-sidebar @change="onChange" v-model="activeKey">
          <van-sidebar-item title="全部" />
          <van-sidebar-item v-for="(v, key) in lotteryitem" :key="key" :title="v.name" />
        </van-sidebar>
      </div> -->
      <div class="right">
        <!-- <div style="padding: 12px 12px 0;">
          <van-button block size="large" color="linear-gradient(90deg,#8e4ff1,#c571cf)"
            style="height: 50px;border: 2px solid #fff;border-radius: 10px;font-size: 16px;" @click="getGameItem">
            Hoạt động
          </van-button>
        </div> -->
        <van-pull-refresh :border="false" class="list-wrapper" :pulling-text="$t('pullingtext')"
          :loosing-text="$t('loosingtext')" :loading-text="$t('loadingtext')" v-model="isLoading" @refresh="onRefresh">
          <van-grid :column-num="3">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="$config.baseURL + v.ico">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
              <span style="text-align: center;">
                {{ v.name || '' }}
                <!-- {{ v.name }} -->
              </span>
              <!-- <span>{{ v.desc }}</span> -->
              <span>121{{ $t('people') }}</span>
            </van-grid-item>
          </van-grid>
        </van-pull-refresh>
      </div>
    </div>


  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      activeKey: 0,

    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t('refreshSuccessful'));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        console.log("gameitem", res.data);
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: { class: index },
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        this.lotteryitem = res.data;
      })
    },
    toService() {
      let tel = this.$store.getters.getUserInfo.tel;
      let kefu = this.$store.getters.getBaseInfo.kefu.replace(/\s*/g, "");
      let metadata = `&metadata={"address":"111","age":"18","comment":"111","email":"123@qq.com","gender":"男","name":"${tel}","qq":"123", "tel":"${tel}","weibo":"111","weixin":"111"}`;
      return window.location.href = kefu + metadata;
    },
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.newnavbar {
  // background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
  background: linear-gradient(30deg, #7d76ef, #d63f8c);

}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #7e5678;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #7e5678;
  font-size: 35px;
  text-align: center;
}

.van-sidebar-item {
  font-size: 35px;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
}

.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #f2f2f5;
  ;
}

.convention-item {
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

.convention-item .left {
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

}

.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}

.convention-item .right .list-wrapper .game_item_img {
  width: 150px;
  height: 150px;
}

.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #000;
}

.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}

.van-grid-item {
  padding: 5px;

}

::v-deep .van-grid-item__content--center {
  border-radius: 15px;
  border: 2PX solid #b78aed;
}

::v-deep .van-image__img {
  border-radius: 15px;
}

::v-deep .van-pull-refresh__track {}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
</style>
