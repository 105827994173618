<template>
  <div class="movie-hall page">
    <van-nav-bar class="nav-bar newnavbar" :title="$t('tabbar2')" @click-right="toService">
      <template #right>
        <van-icon name="chat-o" size="24" color="#fff" />
      </template>
    </van-nav-bar>
    <div style="background-color: #fff; padding-bottom: 10px;">
      <van-tabs v-model="active" title-inactive-color="#442889" title-active-color="#c571cf" animated swipeable
        @change="OnChange">
        <van-tab :title="$t('uptodate')" :name="888" :title-style="{
      fontWeight: 'bold',
      background: 'url(/img/new.png) no-repeat top right',
      backgroundSize: '30%',
    }"></van-tab>
        <van-tab v-for="(v, key) in videolitem" :key="key" :title="v.name" :name="v.id" :title-style="{
      fontWeight: 'bold',
    }"></van-tab>
      </van-tabs>
    </div>
    <swiper style="background-color: #fff;" class="video_swiper" ref="swiper" :options="videoSwiperOption"
      @slideChange="itemChange">
      <swiper-slide v-for="(v, key) in videolitem" :key="key">
        <div class="movie-list-tab">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('pullingtext')"
            :loosing-text="$t('loosingtext')" :loading-text="$t('loadingtext')">
            <div class="hot-recommend-div">
              <van-list v-model="loading" :finished="finished" :immediate-check="false"
                :loading-text="$t('loadingtext')" :finished-text="$t('finishedtext')" @load="onLoad">
                <div class="list-item">
                  <div class="movie-list-item" v-for="(v, key) in videolist" :key="key" @click="toPlayVideo(v.id)">
                    <van-image class="cover_img" round :src="v.vod_pic" width="100%" height="100px">
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                      <template v-slot:error><van-image src="/img/error.png" /></template>
                    </van-image>
                    <div class="movie-list-item-bottom" style="bottom: 33px;">
                      <div class="movie-time-div">
                        <span>{{ v.vod_name }}</span>
                        <span style="font-size: 12px;">{{ $t('play') }}:{{ v.count }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
          </van-pull-refresh>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 888,
      isLoading: false,
      count: 0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number: 0,
      page: 0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      }
    };
  },
  methods: {
    getVideoClass() {
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res => {
        this.videolitem = res.data;
        console.log("this.videolitem", this.videolitem);
      })
    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/PlayVideo?id=' + id })
      }

    },
    itemChange() {
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList() {
      this.$http({
        method: 'get',
        data: { id: this.active, page: this.page },
        url: 'video_list'
      }).then(res => {
        console.log("videolist", res.data.data);
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
      this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    OnChange() {
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t('refreshSuccessful'));
      }, 500);
    },
    toService() {
      let tel = this.$store.getters.getUserInfo.tel;
      let kefu = this.$store.getters.getBaseInfo.kefu.replace(/\s*/g, "");
      let metadata = `&metadata={"address":"111","age":"18","comment":"111","email":"123@qq.com","gender":"男","name":"${tel}","qq":"123", "tel":"${tel}","weibo":"111","weixin":"111"}`;
      return window.location.href = kefu + metadata;
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
  }
};
</script>

<style lang='less' scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.newnavbar {
  // background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
  background: linear-gradient(30deg, #7d76ef, #d63f8c);

}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall {
  display: flex;
  flex-direction: column;
  bottom: 100px;
  background: #f2f2f5;
}

::v-deep .van-tabs__nav {
  // background: linear-gradient(to right, rgb(126, 86, 120), rgb(230, 195, 161));
  // background: linear-gradient(30deg, #7d76ef, #d63f8c);
}

::v-deep .van-tab {
  color: #ffffff;
  font-size: 30px;
}

::v-deep .van-tabs__line {
  bottom: 20px;
  width: 80px;
  height: 10px;
  border-radius: 8px;
  background-color: #c571cf;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.video_swiper {
  width: 100%;
  flex: 1;

  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}

.movie-list-tab {
  overflow: auto;
  height: 100%;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000;
  font-size: 35px;
}

.movie-list-tab .hot-recommend-div {
  height: 100%;
  margin: 10px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100vw;
  //overflow: auto;
}

.movie-list-tab .hot-recommend-div .van-list {
  width: 100%;
}

.list-item {
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list-item .movie-list-item {
  width: calc(50% - 8px);
}

// .list-item .movie-list-item:nth-child(odd) {
//   margin-right: 20px;
// }

.movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  // height: 290px;
}

.movie-list-item {
  margin-bottom: -50px;
}

.list-item .movie-list-item-bottom {
  position: relative;
  width: 100%;
  bottom: 42px;
}

.list-item .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
  height: 60px;
}

.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}
</style>
