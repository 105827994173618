<template>
  <div class="container page">
    <van-nav-bar :title="$t('addBankCard')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{ $t('payCardInfoPlaceholder') }}</div>
      <van-cell-group>
        <van-field v-model="username" :label="$t('fullName')" :placeholder="$t('fullName')" />
        <van-field v-model="bank" :label="$t('bankName')" :placeholder="$t('bankName')" />
        <van-field v-model="bankfenhang" v-if="$i18n.locale != 'en'" :label="$t('branchName')"
          :placeholder="$t('branchName')" />
        <van-field v-model="fhnumber" v-if="$i18n.locale != 'en'" :label="$t('branchNumber')"
          :placeholder="$t('branchNumber')" />
        <van-field v-model="bankid" :label="$t('bankCardNumber')" :placeholder="$t('bankCardNumber')" />
      </van-cell-group>
      <p>{{ $t('addBankCardPrompt') }}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{ $t('addBankCard') }}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }">
      <van-picker show-toolbar :columns="banks" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank: false,
      bankid: "",
      username: "",
      bank: "",
      bankfenhang: "",
      fhnumber: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    bindCard() {
      if (!this.username === "") {
        return this.$toast(this.$t('selectBank'));
      }
      if (!this.bank === "") {
        return this.$toast(this.$t('selectBank'));
      }
      if (!this.bankfenhang === "") {
        return this.$toast(this.$t('selectBank'));
      }
      if (!this.fhnumber === "") {
        return this.$toast(this.$t('selectBank'));
      }
      if (!this.bankid === "") {
        return this.$toast(this.$t('selectBank'));
      }
      this.$http({
        method: 'post',
        data: { username: this.username, bank: this.bank, bankfenhang: this.bankfenhang, fhnumber: this.fhnumber, bankid: this.bankid },
        url: 'user_set_bank'
      }).then(res => {
        if (res.code === 200) {
          this.$router.push({ path: '/Mine' })
          this.$toast(res.msg);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks() {
      this.showBank = true;
    },
    onConfirm(value) {
      this.bank = value.text;
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 32px;
  line-height: 80px;
}

.van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.bankbox {
  padding: 15px;
  color: #000;
  background-color: #fff;
}

.bankbox .title {
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}

.main-box {
  background: #fff;

}

.main-box .label {
  padding: 20px;
  font-size: 35px;
  color: #797878;
}

::v-deep .van-picker__toolbar {
  height: 50px;
}

::v-deep .van-picker__cancel,
.van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}

::v-deep .van-picker-column {
  font-size: 40px;
}

.main-box p {
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}

.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  // background: linear-gradient(90deg, #e6c3a1, #7e5678);
  background: linear-gradient(90deg, #6529c9, #cc2996);
}
</style>
