<template>
	<div class="releaseItem" @click="profile">
		<div class="imgBox">
			<van-image :src="rowData.img_url" width="100%" height="100%">
			</van-image>
			<span><i>{{ $t('highEnd') }}</i></span>
			<div class="infoBox">
				<van-icon name="location" size="14" />
				<span style="font-size: 12px; margin: 0 0 0 2px; overflow: hidden; white-space: nowrap; width: 50%;">
					{{ rowData.address || '' }}
				</span>
				<div class="wantyuebtn">{{ $t('reservation') }}</div>
			</div>
		</div>
		<div class="tagBox" style="color: #666;">{{ $t('beautifulGirl') }}</div>
		<div class="di">
			<div>
				<span style="display: inline-block;
								margin-right: 2px;
								width: 12px;
								height: 12px;
								border-radius: 50%;
								background: #efba8f;"></span>
				<span>{{ rowData.xuanfei_name || '' }}</span>
			</div>
			<div>
				<van-icon name="like" color="#dc63a1" />
				<span>{{ rowData.dianzan }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "releaseItem",
	props: {
		rowData: {
			type: Object,
			default: () => { }
		}
	},
	data() {
		return {

		}
	},
	methods: {
		profile() {
			this.$router.push({
				path: '/profile?id=' + this.rowData.id
			});
		},
	}
}
</script>

<style lang="less" scoped>
.releaseItem {
	width: 100%;
	margin-bottom: 15px;

	.imgBox {
		position: relative;
		overflow: hidden;
		border-radius: 8px;
		height: 500px;

		>span {
			position: absolute;
			z-index: 9;
			background-color: #ebcaaf;
			color: #8d684b;
			transform: rotate(45deg);
			width: 150px;
			height: 150px;
			top: -80px;
			right: -80px;

			>i {
				position: absolute;
				bottom: 5px;
				left: 50%;
				transform: translateX(-50%);
				font-weight: bold;
				font-size: 28px;
			}
		}

		.infoBox {
			position: absolute;
			bottom: 0;
			left: 0;
			background: rgba(0, 0, 0, .4);
			display: flex;
			align-items: center;
			color: #fff;
			width: 100%;
			height: 60px;
			padding: 0 10px;

			.wantyuebtn {
				border: 1px solid #fff;
				padding: 0 8px;
				border-radius: 8px;
				opacity: 0.9;
				font-size: 22px;
				margin-left: auto;
				width: 50%;
				text-align: center;
			}
		}
	}

	.tagBox {
		font-size: 26px;
		margin: 5px 0;
	}

	.di {
		display: flex;
		justify-content: space-between;
		font-size: 26px;
		color: #666;
	}
}
</style>