<template>
    <div class="container page">
        <van-nav-bar :title="$t('Detail')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back" />
            </template>
        </van-nav-bar>
        <div class="HistoryPage">
            <div>{{ info.goods_name }}</div>
            <div>{{ new Date(info.addtime * 1000).toLocaleDateString().replace(/\//g, "-") + " " + new
            Date(info.addtime * 1000).toTimeString().substr(0, 8) }}</div>
            <div v-html="goods_info"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: {},
            goods_info: "",
        }
    },
    methods: {
        back() {
            return window.history.back();
        },
        formatRichText(html) {
            return html.replace(/<img/g, '<img style="max-width: 100%;"');;
        },
    },
    mounted() {
        // 详情
        this.$httpNew({
            method: 'post',
            data: {
                id: this.$route.query.id,
            },
            url: 'detail'
        }).then(res => {
            if (res.code == 0) {
                this.info = res.data || {};
                this.goods_info = this.formatRichText(res.data.goods_info);
            } else {
                this.info = {};
                this.goods_info = "";
            }
        })
    },
}
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.HistoryPage {
    padding: 10px;
    background: #fff;
    // overflow: hidden;

    >div:nth-child(1) {
        text-align: center;
        font-weight: 700;
    }

    >div:nth-child(2) {
        text-align: right;
        margin: 10px 0;
        font-size: 12px;
    }
}
</style>